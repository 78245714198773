/* Import External Modules */
import React from 'react';
import ReactDOM from 'react-dom/client';

/* Import local functions */
import SiteRoutes from './SiteRoutes';
import { AuthProvider } from './AuthContext'; 

/* Import StyleSheets */
/* External Third Party Libraries ... PRIME REACT */
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";
/* Internal CSS */
import './css/common.css';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <AuthProvider>  {/* Provides the user_role variable across all pages */}
      <SiteRoutes />  {/* Contains all the routing info for the site navigation routing */}
    </AuthProvider>
  </React.StrictMode>
);

/* References:
   ===========

   The source code in this project draws heavily from youtube videos, LinkedIn Learning courses and Generative AI code snippets 
   (eg. ChatGPT 4o Mini). The coding team makes no claim that this code is purely our work. Like any developer, the focus is on 
   leveraging third party libraries, components and code snippets from any reputable resource. 

   Our systems documentation is by our own hand. Our project proposals and cost benefit analysis's are our research. Only our code
   is supported by any and all technologies within our grasp and budget, as this is not a test on programming skills. This is a 
   professional experience project, simulating what a small team could accomplish if roleplaying real developers in the private
   sector. Of course they would use whatever tool and scrap of code that fit the purpose. Realistically, they could have used
   several different languages, mushed it all together and rammed it into the cloud with no concern for next year's team, or 
   consistency across platforms and future proofing compatibility.

   We acknowledge and freely admit using ChatGPT to inspire, educate and demonstrate problem solving techniques as we rose to the
   challenge of learning multiple new technologies in a very short period of time to produce a commercially functional product.

   For the most part, all external code snippets have been studied, modified and merged in with our workflows and existing modules
   as at least 90% of all attempts to get instantly working code from the AI was either wrong or did not suit the purpose. All files 
   can be explained and supported by at least one of us, and most code segments flow similarily, with the same techniques used for 
   all CRUD and data processing workflows. 

   It was important to the team, that we embrace our supervisors suggestions to adhere to the latest "modern react typescript function
   component" style of coding, and to incorporate "best practice" coding and technology usage so that this project stood a decent 
   chance of being compatible with the next team assigned to continue our work.

   I (John McLaughlin), have a full ChatGPT conversation history stored in a google profile ready to go in the event of an academic 
   integrity enquiry or audit in my future. I cannot speak to the rest of my team's record keeping. 
    

*/