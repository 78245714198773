import {createBrowserRouter, RouterProvider} from "react-router-dom"; //Import React Router components to create and provide routing functionalities
import Home from "./routes/Home"; //Import Root component for the main route
// import SignUp from "./routes/SignUp"; //Import SignUp component for user registration route
import SignUp from "./routes/UC1100-SignUp"; //Import SignIn component for user login route
import SignIn from "./routes/UC1200-SignIn"; //Import SignIn component for user login route
import AboutUs from "./routes/AboutUs"; //Import AboutUs component for the "About Us" page route
import ContactUs from "./routes/ContactUs"; //Import ContactUs component for the "Contact Us" page route
import TermsAndConditions from "./routes/legal/TermsAndConditions"; //Import TermsAndConditions component for displaying legal terms
import SiteMenuBar from "./components/SiteMenuBar"; //Import SiteMenuBar component to be displayed on every page
import SiteFooter from "./components/SiteFooter"; //Import SiteFooter component to be displayed on every page
import SignOut from './routes/SignOut'; //Import SignOut component for the route after signing out

const router = createBrowserRouter([ //Define the router with routes, each containing a path and the corresponding component structure
{
  path: "/",
  element:(
    <SiteMenuBar>
      <Home/>
      <SiteFooter />
    </SiteMenuBar>
  )
},
{
  path: "/home",
  element:(
    <SiteMenuBar>
      <Home/>
      <SiteFooter />
    </SiteMenuBar>
  )
},
{
  path: "/signup",
  element:(
    <SiteMenuBar>
      <SignUp />
      <SiteFooter />
    </SiteMenuBar>
  )
},
{
  path: "/signin",
  element:(
    <SiteMenuBar>
      <SignIn />
      <SiteFooter />
    </SiteMenuBar>
  )
},
{
  path: "/signout",
  element:(
    <SiteMenuBar>
      <SignOut />
      <SiteFooter />
    </SiteMenuBar>
  )
},
{
  path: "/about",
  element:(
    <SiteMenuBar>
      <AboutUs />
      <SiteFooter />
    </SiteMenuBar>
  )
},
{
  path: "/contactus",
  element:(
    <SiteMenuBar>
      <ContactUs />
      <SiteFooter />
    </SiteMenuBar>
  )
},
{
  path: "/legal/termsconditions",
  element:(
    <SiteMenuBar>
      <TermsAndConditions />
      <SiteFooter />
    </SiteMenuBar>
  )
},
]);

export default function SiteRoutes()
{
  return <RouterProvider router={router} />;
}
