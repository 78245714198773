import React from "react"; //Import React
import {Card} from 'primereact/card'; //Import Card 
import {Divider} from 'primereact/divider'; //Import Divider
import logo from '../images/logo.png'; //Import Logo

/* Import the local CSS files */
import "../css/common.css";

const AboutUs = () =>
{
  const googleMapsUrl = "https:/www.google.com/maps/embed/v1/place?key=YOUR_GOOGLE_MAPS_API_KEY&q=YourAddress";
  
  return(
    <div>
        <div className="about-us-container" style={{padding: '2rem'}}>
            <Card style={{textAlign: 'center'}}>
                {/* Company Logo */}
                <img
                    src={logo}
                    alt="Your Company Logo"
                    style={{width: '200px', marginBottom: '1rem'}}
                />
                {/* Company Name */}
                <h1>
                    Good Day Construction
                </h1>

                <Divider />
                {/* About the Company */}
                <p>
                    Welcome to Good Day Construction
                </p>

                <Divider />

                {/* Google Map Embed */}
                <div style={{marginBottom: '2rem'}}>
                <h2>
                    Our Corporate Office
                </h2>
                <iframe
                    title="Google Map"
                    width="100%"
                    height="300px"
                    frameBorder="0"
                    style={{border: 0}}
                    src={googleMapsUrl}
                    allowFullScreen
                ></iframe>
                </div>

                {/* Contact Information */}
                <div style={{textAlign: 'left', margin: '2rem 0'}}>
                <h3>
                    Contact Us
                </h3>
                <p>
                    <strong>
                        Phone (Local):&nbsp;
                    </strong>
                        (02) 1234-5678
                </p>
                <p>
                    <strong>
                        Phone (International):&nbsp;
                    </strong>
                        +61 (2) 1234-5678
                </p>
                <p>
                    <strong>
                        Fax (Local):&nbsp;
                    </strong>
                        (02) 1234-5678
                </p>
                <p>
                    <strong>
                        Fax (International):&nbsp;
                    </strong>
                        +61 (2) 1234-5678
                </p>
                <p>
                    <strong>
                        Email:&nbsp;
                    </strong>
                        Contact@gooddayconstruction.com
                </p>
                <p>
                    <strong>
                        Address:&nbsp;
                    </strong>
                        1234 Main Street, Suite 500, City, State, ZIP Code
                </p>
                </div>

            <Divider />

            {/* Business and Technical Support Hours */}
            <div style={{textAlign: 'left', margin: '2rem 0'}}>
                <h3>
                    Business Hours
                </h3>
                <p>
                    <strong>
                        Monday - Friday:&nbsp;
                    </strong>
                        8:00 AM - 6:00 PM
                </p>
                <p>
                    <strong>
                        Saturday:&nbsp;
                    </strong>
                        9:00 AM - 1:00 PM
                </p>
                <p>
                    <strong>
                        Sunday:&nbsp;
                    </strong>
                        Closed
                </p>
                <Divider />
                <h3>
                    Technical Support Hours
                </h3>
                <p>
                    <strong>
                        Monday - Friday:&nbsp;
                    </strong>
                        24 hours
                </p>
                <p>
                    <strong>
                        Saturday:&nbsp;
                    </strong>
                        9:00 AM - 5:00 PM
                </p>
                <p>
                    <strong>
                        Sunday:&nbsp;
                    </strong>
                        Emergency support only
                </p>
            </div>
        </Card>
    </div>
    </div>
  );
};

export default AboutUs;
