/* Import External / Third Party modules */
import React, {useState} from "react";
import {Link, useNavigate} from 'react-router-dom';
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {Message} from "primereact/message";

/* Import External CSS styles */
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

/* Import local CSS Styles */
import "../css/common.css";    // Common to all
import "../css/SignIn.css";    // Specific to this page

/* Setup custom interfaces/classes */
interface FormState
{
  email: string;
  password: string;
}

interface FormErrors
{
  email?: string;
  password?: string;
}

/**
 * UC1200-SignIn  Display the Sign In form to the user
 * @returns nothing
 */
export default function SignIn()
{
    const APIroute = "signin";
    
    const [formState, setFormState] = useState<FormState>(
    {
        email: "",
        password: "",
    });

    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [isFormVisible, setFormVisible] = useState<boolean>(true);
    const navigate = useNavigate();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    {
        setFormState({ ...formState, [e.target.name]: e.target.value});
    };

    const handleClose = () =>
    {
        navigate('/home');
    };

    const validateEmail = (email: string): boolean =>
    {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateEmailField = () =>
    {
        if(!validateEmail(formState.email))
        {
            setFormErrors(errors => ({ ...errors, email: "Invalid email address" }));
        }
        else
        {
            setFormErrors(errors => ({ ...errors, email: undefined }));
        }
    };

    const validatePassword = () =>
    {
        if(!formState.password)
        {
            setFormErrors(errors => ({ ...errors, password: "Password is required" }));
        }
        else
        {
            setFormErrors(errors => ({ ...errors, password: undefined }));
        }
    };

    React.useEffect(() =>
    {
        const validateForm = (): boolean =>
        {
            const isEmailValid = validateEmail(formState.email);
            const isPasswordValid = formState.password.length > 0;
            return isEmailValid && isPasswordValid;
        };

        setIsFormValid(validateForm());
    }, [formState]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) =>
    {
        e.preventDefault();
        if(!isFormValid)
        {
            return;
        }

        const payload =
        {
            email: formState.email,
            password: formState.password,
        };

        try
        {
            const response = await fetch("https://api.example.com/signin", 
            {
                method: "POST",
                headers:
                {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            if(response.ok)
            {
                alert("Sign in successful!");
            }
            else
            {
                alert("Sign in failed.");
            }
        }
        catch(error)
        {
            console.error("Error during sign in:", error);
            alert("An error occurred during sign in.");
        }
    };

    if(!isFormVisible)
    {
        return null;
    }

    return(
        <div className="page-container">
            <div className="background-overlay"></div>
            <div className="dimming-overlay"></div> 
            
            <div className="modal-container">
                <div className="modal-content">
                <i className="pi pi-times close-icon" onClick={handleClose}></i>
                <div className="p-fluid p-formgrid p-grid">
                    <h3>
                        Sign In to your account
                    </h3>
                    <p>
                        Please enter your email and password to sign in.
                    </p>
                    <div className="p-field p-col-12 p-md-6 custom-field-padding">
                    <p>
                        Don't have an account?&nbsp;
                        <Link to="/signup/">
                            Sign up instead
                        </Link>
                    </p>
                    <p>
                        Forgot your password?&nbsp;
                        <Link to="/signin_resetpassword/">
                            Click here
                        </Link>
                    </p>
                    </div>
                        <form onSubmit={handleSubmit}>
                            <div className="p-field p-col-12 custom-field-padding">
                                <label htmlFor="email">
                                    Email:
                                </label>
                                <InputText
                                id="email"
                                name="email"
                                value={formState.email}
                                onChange={handleInputChange}
                                onBlur={validateEmailField}
                                required
                                />
                                {formErrors.email && (
                                <Message severity="error" text={formErrors.email} />
                                )}
                            </div>
                            <div className="p-field p-col-12 custom-field-padding">
                                <label htmlFor="password">
                                    Password:
                                </label>
                                <Password
                                id="password"
                                name="password"
                                value={formState.password}
                                onChange={handleInputChange}
                                onBlur={validatePassword}
                                toggleMask
                                required
                                feedback={false}
                                />
                                {formErrors.password && (
                                <Message severity="error" text={formErrors.password} />
                                )}
                            </div>
                            <div className="p-col-12 custom-field-padding">
                                {/* Submit button, disabled if the form is not valid */}
                                <Button
                                    label="Sign In"
                                    icon="pi pi-check"
                                    type="submit"
                                    disabled={!isFormValid}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
      
}