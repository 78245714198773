import {Divider} from 'primereact/divider'; //Import Divider for styling

export default function SiteFooter()
{
  return(
    <footer style={{backgroundColor: '#f4f4f4', padding: '1rem', textAlign: 'center'}}>
        <div>
            <h4>
                Good Day Construction
            </h4>
            <p>
                &copy; {new Date().getFullYear()} Good Day Construction. All rights reserved.
            </p>
            {/* Social Media Links 
            
              October 2024 - Client informed us that there will be no Facebook or social media presence for the GDC organisation 

            <div style={{marginBottom: '1rem'}}>
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" style={{margin: '0 10px'}}>
                    <i className="pi pi-facebook" style={{fontSize: '1.5rem'}}></i>
                </a>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" style={{margin: '0 10px'}}>
                    <i className="pi pi-instagram" style={{fontSize: '1.5rem'}}></i>
                </a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" style={{margin: '0 10px'}}>
                    <i className="pi pi-twitter" style={{fontSize: '1.5rem'}}></i>
                </a>
            </div> */}
            
            <Divider />  {/* Inserts a horizontal bar onscreen. */}

            {/* Footer Links */}
            <div>
                <a href="/about" style={{margin: '0 8px', textDecoration: 'none', color: '#555'}}>
                    About Us
                </a>
                <a href="/contactus" style={{margin: '0 8px', textDecoration: 'none', color: '#555'}}>
                    Contact Us
                </a>
                <a href="/privacy-policy" style={{margin: '0 8px', textDecoration: 'none', color: '#555'}}>
                    Privacy Policy
                </a>
                <a href="/legal/termsconditions" style={{margin: '0 8px', textDecoration: 'none', color: '#555'}}>
                    Terms of Service
                </a>
            </div>
        </div>
    </footer>
  );
}
