import { useNavigate } from 'react-router-dom';
import '../css/SignOut.css';

export default function SignOut()
{
  const navigate = useNavigate();
  const handleHomePageRedirect = () =>
  {
    navigate('/home');
  };

  return(
    <div className="page-container">
      <div className="background-overlay"></div>
      <div className="dimming-overlay"></div>

      <div className="signout-container">
        <div className="signout-content">
          <h1>
            You have successfully signed out!
          </h1>
          <button className="return-home-btn" onClick={handleHomePageRedirect}>
            Return to our Home Page
          </button>
        </div>
      </div>
    </div>
  );
}
