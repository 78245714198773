import React from 'react';
import { Menubar } from 'primereact/menubar';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.png'; // Replace with your logo path
import { Button } from 'primereact/button'; // Import PrimeReact Button
import { useAuth } from '../AuthContext';  // Import the useAuth hook

export default function NavBar({ children }: { children: React.ReactNode }) { // Accept children prop to wrap page content
  const { user, signOut } = useAuth();  // Get the current user and signOut function from the context
  const navigate = useNavigate();

  // Define the menu items based on the user's signed-in state
  const getMenuItems = () => {
    if (!user) {
      return []; // No main menu items for signed-out users
    } else {
      return [
        {
          label: 'Product Management',
          icon: 'pi pi-box',
          items: [
            { label: 'Products', command: () => navigate('/products') },
            { label: 'Licences', command: () => navigate('/licences') }
          ]
        },
        {
          label: 'License Management',
          icon: 'pi pi-id-card',
          command: () => navigate('/license-management')
        },
        {
          label: 'Customer Management',
          icon: 'pi pi-users',
          items: [
            { label: 'Customer Details & Configurations', command: () => navigate('/customer-details') }
          ]
        },
        {
          label: 'Sign Out',
          icon: 'pi pi-sign-out',
          command: () => {
            signOut();  // Sign the user out
            navigate('/signout');
          }
        }
      ];
    }
  };

  const items = getMenuItems();

  const start = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img alt="logo" src={logo} height="40" className="mr-2" />
      <h3 style={{ marginLeft: '10px', color: '#333' }}>Good Day Construction</h3>
    </div>
  );

  // Sign in and Sign up buttons for users who are not signed in
  const end = !user && (
    <div>
      <Button
        label="Sign In"
        icon="pi pi-sign-in"
        className="p-button-text"
        onClick={() => navigate('/signin')}
        style={{ marginRight: '10px' }}
      />
      <Button
        label="Sign Up"
        icon="pi pi-user-plus"
        className="p-button-text"
        onClick={() => navigate('/signup')}
      />
    </div>
  );

  return (
    <div>
      {/* Fixed menubar */}
      <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
        <Menubar
          model={items}
          start={start}
          end={end}
          style={{ backgroundColor: '#f4f4f4' }}
        />
      </div>

      {/* Content wrapper with padding to prevent overlap */}
      <div style={{ paddingTop: '70px' }}>
        {children}
      </div>
    </div>
  );
}
