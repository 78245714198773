import React, {useState, useRef} from 'react'; // Import React
import {InputText} from 'primereact/inputtext'; // Import InputText from Prime React
import {InputTextarea} from 'primereact/inputtextarea'; // Import InputTextarea from Prime React
import {Button} from 'primereact/button'; // Import Button from Prime React
import {Toast} from 'primereact/toast'; // Import Toast from Prime React
import PhoneInput from 'react-phone-input-2'; // Import PhoneInput from Prime React
import 'react-phone-input-2/lib/style.css'; // Import the styles for the phone input
import '../css/ContactUs.css'; // Import CSS for custom styles

const ContactUs = () =>
{
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const toast = useRef<Toast>(null);

  const validateForm = () =>
  {
    const newErrors: {[key: string]: string} = {};
    if(!firstName) newErrors.firstName = 'First name is required';
    if(!lastName) newErrors.lastName = 'Last name is required';
    if(!companyName) newErrors.companyName = 'Company name is required';
    if(!email || !/\S+@\S+\.\S+/.test(email)) newErrors.email = 'Valid email is required';
    if(!phoneNumber || phoneNumber.length < 9) newErrors.phoneNumber = 'Valid phone number is required';
    if(!message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const handlePhoneChange = (value: string) => setPhoneNumber(value);

  const handleSubmit = () =>
  {
    const formErrors = validateForm();
    if(Object.keys(formErrors).length > 0)
    {
      setErrors(formErrors);
      return;
    }

    const emailContent = {firstName, lastName, companyName, email, phoneNumber, message};
    console.log('Form Data:', emailContent);

    toast.current?.show(
    {
      severity: 'success',
      summary: 'Email Sent',
      detail: 'Your message has been sent successfully!',
      life: 3000
    });

    setFirstName('');
    setLastName('');
    setCompanyName('');
    setEmail('');
    setPhoneNumber('');
    setMessage('');
    setErrors({});
  };

  return(
    <div className="contact-us-container">
      <Toast ref={toast} />

      <div className="form-section">
        <h2>
          Contact Us
        </h2>

        <div className="form-field">
          <label htmlFor="firstName">First Name</label>
          <InputText id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          {errors.firstName && <small className="p-error">{errors.firstName}</small>}
        </div>

        <div className="form-field">
          <label htmlFor="lastName">Last Name</label>
          <InputText id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
          {errors.lastName && <small className="p-error">{errors.lastName}</small>}
        </div>

        <div className="form-field">
          <label htmlFor="companyName">Company Name</label>
          <InputText id="companyName" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
          {errors.companyName && <small className="p-error">{errors.companyName}</small>}
        </div>

        <div className="form-field">
          <label htmlFor="email">Email</label>
          <InputText id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          {errors.email && <small className="p-error">{errors.email}</small>}
        </div>

        <div className="form-field">
          <label htmlFor="phoneNumber">
            Contact Number
          </label>
          <PhoneInput
            country={'au'}
            value={phoneNumber}
            onChange={handlePhoneChange}
            inputStyle={{ width: '100%' }}
          />
          {errors.phoneNumber && <small className="p-error">{errors.phoneNumber}</small>}
        </div>

        <div className="form-field">
          <label htmlFor="message">
            Message
          </label>
          <InputTextarea
            id="message"
            value={message}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}
            rows={5}
          />
          {errors.message && <small className="p-error">{errors.message}</small>}
        </div>

        <Button label="Submit" onClick={handleSubmit} className="submit-btn" />
      </div>

      <div className="company-details">
        <h3>
          Our Company
        </h3>
        <p>
          <strong>
            Company Name:&nbsp;
          </strong>
          Good Day Construction
        </p>
        <p>
          <strong>
            Address:&nbsp;
          </strong>
          1234 Construction Ave, Build City, BC
        </p>
        <p>
          <strong>
            Phone (Local):&nbsp;
          </strong>
          (02) 1234-5678
        </p>
        <p>
          <strong>
            Phone (International):&nbsp;
          </strong>
          +61 (2) 1234-5678
        </p>
        <p>
          <strong>
            Fax (Local):&nbsp;
          </strong>
          (02) 1234-5678
        </p>
        <p>
          <strong>
            Fax (International):&nbsp;
          </strong>
          +61 (2) 1234-5678
          </p>
        <p>
          <strong>
            Email:&nbsp;
          </strong>
          Contact@gooddayconstruction.com
        </p>
      </div>
    </div>
  );
};

export default ContactUs;
