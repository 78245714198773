/* References:  
   ChatGPT Inspired Module - 
      Provides a solution to the challeng of how to inform all pages of the SignIn state of the visitor
      Are they:  signed_out | customer | system_admin 
      Each page will have access to the user_role variable and react to whatever the value is.
*/

import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the type for user and context
interface AuthContextType {
  user: string | null;
  signIn: (user: string) => void;
  signOut: () => void;
}

// Create a context with default values
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create a provider component
export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<string | null>(null);

  const signIn = (newUser: string) => setUser(newUser);
  const signOut = () => setUser(null);

  return (
    <AuthContext.Provider value={{ user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}

// Custom hook to access the auth context
export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
