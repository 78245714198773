/* References: Code based on 'Prime React Final Form'  @  https://www.primefaces.org/primereact-v8/reactfinalform/ */

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Field, FieldMetaState } from 'react-final-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../css/PopupForm.css';

/* Error Messages returned through the validation function */

type ErrorData = {
    first_name?: string;
    last_name?: string;
    company?: string;
    email?: string;
    password?: string;
    accept?: string;
}

type FormData = {
    first_name: string | null;
    last_name: string | null;
    company: string | null;
    email: string | null;
    password: string | null;
    confirm_password: string | null;
    accept: boolean;
}

export default function SignUp() {

    const API = process.env.API_URL_UC1101 as string;
    const navigate = useNavigate();
    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState<FormData>({
        first_name: null,
        last_name: null,
        company: null,
        email: null,
        password: null,
        confirm_password: null,
        accept: false
    });
    
    const validate = (data: FormData): ErrorData => {

        let errors: ErrorData = {};

        if (!data.first_name) {
            errors.first_name = 'First name is required.';
        }
        if (!data.last_name) {
            errors.last_name = 'Last name is required.';
        }

        if (!data.company) {
            errors.company = 'Company is required.';
        }

        if (!data.email) {
            errors.email = 'Email is required.';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
            errors.email = 'Invalid email address. E.g. example@email.com';
        }

        if (!data.password) {
            errors.password = 'Password is required.';
        }

        if (!data.confirm_password) {
            errors.password = 'Confirmation of the password is required.';
        } else if(data.confirm_password != data.password){
            errors.password = 'Password does not match!. Try again';
        }

        return errors;
    };

    const onSubmit = async (data: FormData, form: { restart: () => void }) => {
        setFormData(data);
    
        try {
            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
    
            if (response.ok) {
                const result = await response.json();
                console.log('User created successfully:', result);
                setShowMessage(true);
                form.restart(); // Reset form fields if submission is successful
            } else {
                console.error('Failed to create user:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const isFormFieldValid = (meta: FieldMetaState<any>) => !!(meta.touched && meta.error);
    const getFormErrorMessage = (meta: FieldMetaState<any>) => {
        return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
    };

    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(false) } /></div>;
    const passwordHeader = <h6>Pick a password</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    return (
        <div className="background">
        <div className="popup-form-page">
            <div className="background-dimming-overlay"></div>
            <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="flex align-items-center flex-column pt-6 px-3">
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5>Registration Successful!</h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        Your account is registered under the User Name <b>{formData.email}</b> ; it'll be valid next 30 days without activation. Please check <b>{formData.email}</b> for activation instructions.
                    </p>
                </div>
            </Dialog>

            <div className="popup-form-container">
            <div className="flex justify-content-center">
                <div className="card">
                    <h5 className="text-center">Register</h5>
                    <Form onSubmit={onSubmit} initialValues={{ first_name: '', last_name: '', company: '', email: '', password: '', confirm_password: '', accept: false }} validate={validate} render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className="p-fluid">
                            <Field name="first_name" render={({ input, meta }) => (
                                <div className="field">
                                    <span className="p-float-label">
                                        <InputText id="first_name" {...input} autoFocus className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                        <label htmlFor="first_name" className={classNames({ 'p-error': isFormFieldValid(meta) })}>First Name*</label>
                                    </span>
                                    {getFormErrorMessage(meta)}
                                </div>
                            )} />
                            <Field name="last_name" render={({ input, meta }) => (
                                <div className="field">
                                    <span className="p-float-label">
                                        <InputText id="last_name" {...input} autoFocus className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                        <label htmlFor="last_name" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Last Name*</label>
                                    </span>
                                    {getFormErrorMessage(meta)}
                                </div>
                            )} />
                            <Field name="company" render={({ input, meta }) => (
                                <div className="field">
                                    <span className="p-float-label">
                                        <InputText id="company" {...input} autoFocus className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                        <label htmlFor="company" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Company Name*</label>
                                    </span>
                                    {getFormErrorMessage(meta)}
                                </div>
                            )} />
                            <Field name="email" render={({ input, meta }) => (
                                <div className="field">
                                    <span className="p-float-label">
                                        <InputText id="email" {...input} className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                        <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Email Address*</label>
                                    </span>
                                    {getFormErrorMessage(meta)}
                                </div>
                            )} />
                            <Field name="password" render={({ input, meta }) => (
                                <div className="field">
                                    <span className="p-float-label">
                                        <Password id="password" {...input} toggleMask className={classNames({ 'p-invalid': isFormFieldValid(meta) })} header={passwordHeader} footer={passwordFooter} />
                                        <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Password*</label>
                                    </span>
                                    {getFormErrorMessage(meta)}
                                </div>
                            )} />
                            <Field name="confirm_password" render={({ input, meta }) => (
                                <div className="field">
                                    <span className="p-float-label">
                                        <Password id="confirm_password" {...input} toggleMask className={classNames({ 'p-invalid': isFormFieldValid(meta) })} />
                                        <label htmlFor="confirm_password" className={classNames({ 'p-error': isFormFieldValid(meta) })}>Confirm Password*</label>
                                    </span>
                                    {getFormErrorMessage(meta)}
                                </div>
                            )} />
                            <Field name="accept" type="checkbox" render={({ input, meta }) => (
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="accept"
                                        checked={input.checked as boolean}
                                        onChange={input.onChange} 
                                        className={classNames({ 'p-invalid': isFormFieldValid(meta) })} 
                                    />
                                    <label htmlFor="accept" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                                        I agree to the terms and conditions*
                                    </label>
                                    {getFormErrorMessage(meta)}
                                </div>
                            )} />
                            <div className="button-group mt-2">
                                    <Button type="submit" label="Submit" className="mr-2" />
                                    <Button type="button" 
                                        label="Cancel"
                                        className="p-button-secondary" 
                                        onClick={() => navigate('/')} />
                            </div>                                    
                        </form>
                    )} />
                </div>
            </div>
            </div>
        </div>
       </div>   
    );
}
