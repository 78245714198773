import React from 'react';
import {useNavigate} from 'react-router-dom';
import '../css/Home.css';

export default function Home()
{
    const navigate = useNavigate();

    const handleSignIn = () =>
    {
        navigate('/signin');
    };

    const handleSignUp = () =>
    {
        navigate('/signup');
    };

    return(
        <div className="home-container">
            <header className="home-header">
                <h1>
                    Welcome to Good Day Construction
                </h1>
                <p>
                    Improving workplace safety every day!
                </p>
            </header>

            <div className="home-content">
                <div className="home-buttons">
                    <button className="primary-btn" onClick={handleSignIn}>Sign In</button>
                    <button className="secondary-btn" onClick={handleSignUp}>Sign Up</button>
                </div>

                <div className="home-intro">
                    <p>
                        Description goes here
                    </p>
                </div>
            </div>
        </div>
        
    );
}
